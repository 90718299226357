import React from 'react'
import data_touchable from './TouchableHistory.json'
import data_studiomux from './StudiomuxHistory.json'
import data_modstep from './ModstepHistory.json'

export default class History extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            download_sources: data_touchable,
            current_app: "touchable"
        };
    }

    set_app(an_app) {
        this.setState({ download_sources: an_app});
    }

    render() {
        const active_class = "list-group-item text-primary";
        const inactive_class = "list-group-item text-white";

        const touchable_string = "touchable";
        const studiomux_string = "studiomux";
        const modstep_string = "modstep";
        let touchable_class = inactive_class;
        let studiomux_class = inactive_class;
        let modstep_class = inactive_class;

        if (this.state.download_sources == data_touchable) {
          touchable_class = active_class;
        }
        if (this.state.download_sources == data_studiomux) {
          studiomux_class = active_class;

        }
        if (this.state.download_sources == data_modstep) {
          modstep_class = active_class;

        }
        const data = this.state.download_sources;

        const releases_json = data.app_release_list;

        let release_list = releases_json.list.map(item => {
            var build_number = item.build_number
            var build_number = item.build_number
            var server_mac = "https://zerodebug.com/server/"   + item.server_mac
            var server_win = "https://zerodebug.com/server/"   + item.server_win


            return (
                <div class="container py-2 bg-dark border">
                <div>
                <h3 class="text-primary" >Date: {item.date_of_release} </h3>
                <h3 class="text-primary">Version: {item.version} Build: {item.build_number} </h3>
                <div class="container ">
                <h4 class="text-white" color="white"><u>Notes</u></h4>
                <p class="list-group-item1 text-white">{item.notes}</p>
                </div>
                <div class="container ">
                <h4 class="text-white" color="white"><u>Downloads</u></h4>
                <ul class="list-group list-group-horizontal">
                <li class="list-group-item "><a class="" href={server_mac}><i class={releases_json.server_class_mac}></i> <u>{item.server_mac}</u></a></li>
                <li class="list-group-item "><a class="" href={server_win}><i class={releases_json.server_class_win}></i> <u>{item.server_win}</u></a></li>
                </ul>
                </div>
                </div>
                <div>
                <div class="container ">
                    <h4 class="text-white" color="white"><u>App Changes</u></h4>
                    <ul class="list-group1">
                    {item.app_changes.changes.map(descript => {
                  return (
                    <li class="list-group-item1  text-white">{descript}</li>
                  );
                })}
                                    {item.app_changes.changes_ios.map(descript => {
                  return (
                    <li class="list-group-item1 text-white"><i class={releases_json.server_class_mac}></i> {descript}</li>
                  );
                })}
                                    {item.app_changes.changes_android.map(descript => {
                  return (
                    <li class="list-group-item1 text-white"><i class={releases_json.server_class_android}></i> {descript}</li>
                  );
                })}
                                    {item.app_changes.changes_win.map(descript => {
                  return (
                    <li class="list-group-item1 text-white"><i class={releases_json.server_class_win}></i> {descript}</li>
                  );
                })}
                </ul>
                    <h4 class="text-white" color="white"><u>Server Changes</u></h4>
                    <ul class="list-group1">
                    {item.server_changes.changes.map(descript => {
                  return (
                    <li class="list-group-item1 text-white">{descript}</li>
                  );
                })}
                    {item.server_changes.changes_mac.map(descript => {
                  return (
                    <li class="list-group-item1 text-white"><i class={releases_json.server_class_mac}></i> {descript}</li>
                  );
                })}
                {item.server_changes.changes_win.map(descript => {
                  return (
                    <li class="list-group-item1 text-white"><i class={releases_json.server_class_win}></i> {descript}</li>
                  );
                })}
                </ul>
                    </div>
                    </div>
            </div>
            )
        }
            )

        return (
            <div>
                <ul class="list-group list-group-horizontal">
                 <li class={touchable_class}  onMouseDown={this.set_app.bind(this, data_touchable)}><a class=""  ><h1>
                     <u>touchAble Pro</u></h1></a>
                 </li>
                 <li class={studiomux_class}  onMouseDown={this.set_app.bind(this, data_studiomux)}><a class=""  ><h1>
                     <u>studiomux</u></h1></a></li>
                 <li class={modstep_class}  onMouseDown={this.set_app.bind(this, data_modstep)}><a class=""  ><h1>
                     <u>modstep</u></h1></a></li>

                     
                </ul>
                



            <div class="container text-left py-5 mx-5">
            <h1 class="text-white">{releases_json.app_name} Beta Version History</h1>
            
                {release_list}
            </div>
            </div>
        );
    }
}