import React from 'react'
import data from './ProductLandingPageData.json';
import Navbar from 'react-bootstrap/Navbar';
import { styles } from '../Styles.js'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom"

export default class ProductDescriptionContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            product_number: props.product_number,
            page_id: props.page_id,
            submenu_id: props.submenu_id,
            store_item: props.store_item,
            heading_text: props.store_item.list_items[0].description,
            image_source: props.store_item.list_items[0].image_source,
            // desc_list: props.store_item.list_items[0].desc_list,
            favicon_list: props.store_item.list_items[0].favicon_list,
            desc_icon: props.store_item.desc_icon,
            desc_list: props.store_item.desc_list,
            width: 0, height: 0 
            
        };
        this.setState({ store_item: props.store_item });
        this.styles = styles;
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
      }
      
      componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
      }
      
      updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
      }
    

    
    change_image(heading, image_source_path ,desc_list,favicon_list) {
        this.state.heading_text = heading;
        this.setState({ heading_text: heading, image_source: image_source_path });
    }

    render() {

        let inner_content = this.state.store_item.list_items.map((list_item, i) => {
            return (
                <div class="col-12 col-sm-12 product_detail_box my-1 " style={this.styles.backgrounds[i]}>
                    <Link to= {this.state.store_item.app_id}>
                    <div class="row align-items-center product_detail_box py-1" onMouseOver={this.change_image.bind(this, list_item.description, list_item.image_source,list_item.desc_list, list_item.favicon_list )}>
                        <div class="col-12 p-1 p-sm-3 align-items-start text-center text-sm-left inner-product-box">
                        {/* <h3 class="text_color_black align-items-start" style={{ fontSize: "1vw" }}> */}
                        <h3 class="text_color_black align-items-start">

                                {list_item.heading}
                            </h3>
                        </div>
                        {/* <div class="col-0 col-sm-0 align-items-left" style={{}}>
                         <h3><i class="fa fa-500px" aria-hidden="false" style={{color:"black"}}></i></h3>
                        </div> */}
                    </div>
                    </Link>
                </div>
            )
        })


        let an_inner_content = this.state.store_item.list_items.map((list_item, i) => {
            return (
                <div class="col-md-12 col-12 py-3 my-1 px-2 align-items-start text-sm-left" color="white">
                    <li>
                                
                            </li>
                </div>
            )
        })

        let image_content = (
            <div class="col-md-6 col-12 align-items-end px-3" style={{ margin: "0px", padding: "0px" }}>
                <img class="img-fluid float-right" src={this.state.image_source} max-width="50%"></img>
                <div class="text-block-main-bottom">
                    <h4 class="text-center">{this.state.heading_text}</h4>
                </div>
                 <div class="">
                    {/* <h2 class="text-center" style={{ fontSize: "1.5vw" }}>{this.state.heading_text}</h2> */}
                    {/* <h3 class="text-center" >{this.state.heading_text}</h3> */}

                </div>
            </div>
        )
        let inner_content_2;

                if (this.state.desc_list) {
                    inner_content_2 = this.state.desc_list.map((descript, i) => {
                        return (
                            <div class="text-left my-3">
                        {descript}</div>
                        )
                    })
                }





        let box_content2 = (
            <div class="col-md-3 col-12 text-center" >
                 <div class="row-md-3 text-center" >
                 {/* <img src={this.state.desc_icon} width="12%"></img> */}
                </div>
                <div class="row-md-9 text-center" >
            {/* <div class="text-left my-2" style={{ fontSize: "0.9vw" }}> */}
            <div class="text-left my-2">
                 <div class="text-left my-2" style={{ listStyleType: "none" }}> 
                    {inner_content_2}
               </div>
                </div>
            </div>

            </div>
        )

        let box_content = (
            <div class="col-md-3 col-12 " >
                <div class="row align-items-center ml-1" style={{ marginRight: "0px", padding: "0px" }}>
                    {inner_content}
                </div>
            </div>
        )

        let desc_container;
        let box_inner_container;


        if (this.state.width < 1000) {
            box_inner_container = (
                <div class="row py-4 align-items-center">
                    {image_content}
                    {box_content}
                    {box_content2}
                </div>
            )
        }
        else {
            if (this.state.product_number % 2 !== 0) {
                box_inner_container = (
                    <div class="row py-4 align-items-center">
                        {image_content}
                        {box_content}
                        {box_content2}
                    </div>
                )
            }
            else {
                box_inner_container = (
                    <div class="row py-4 align-items-center">
                        {box_content}
                        {box_content2}
                        {image_content}
                    </div>
                )
            }

        }


        if (this.state.product_number % 2 !== 0) {
            desc_container = (
                <div class="container-fluid py-4" style={{ backgroundColor: "#C0C0C0", color: "black"}}>
                    <Link to = {this.state.store_item.app_id}><h1 class="px-5 w-100 text-center" style={{ backgroundColor: "", color: "black" }}>{this.state.store_item.heading}</h1></Link>
                    {/* <h3 class="w-100 text-center" >{this.state.heading_text}</h3> */}
                        {box_inner_container}
                </div>)
        }
        else {
            desc_container = (
                <div class="container-fluid py-4 " style={{ backgroundColor: "#181818", color: "white" }}>
                    <Link to = {this.state.store_item.app_id}><h1 class="px-5 w-100 text-center" style={{ backgroundColor: "", color: "white" }}>{this.state.store_item.heading}</h1></Link>
                    {/* <h3 class="w-100 text-center" >{this.state.heading_text}</h3> */}
                        {box_inner_container}
                </div>
                )
        }

        return (
            <div>
                {desc_container}
            </div>
        );
    }
}
