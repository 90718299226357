const styles = {
    backgrounds: [
        {
            backgroundColor: "var(--accent-bg-color)"
        },
        {
            backgroundColor: "var(--secondary-accent-bg-color)"
        },
        {
            backgroundColor: "var(--third-accent-bg-color)"
        },
        {
            backgroundColor: "var(--fourth-accent-bg-color)"
        },
        {
            backgroundColor: "var(--fifth-accent-bg-color)"
        },
        {
            backgroundColor: "var(--sixth-accent-bg-color)"
        },
        {
            backgroundColor: "var(--seventh-accent-bg-color)"
        },
    ],
    blackwhite: [
        {
            color: "black"
        },
        {
            color: "white"
        }
    ],
    backgrounds_grey: [
        {
            backgroundColor: "#C0C0C0"
        },
        {
            backgroundColor: "#181818"
        }
    ]
};


export{styles}