
import React from 'react'

export default class DefaultPage extends React.Component {


    render() {

        return (
            <div>
                <ul class="navbar-nav w-100 justify-content-start">
                    <li class="nav-item active">
                        <a class="nav-link" href="https://www.zerodebug.com">no page found</a>
                    </li>
                </ul>
            </div>
        );
    }
}






