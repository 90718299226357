import React from 'react';
import '../App.css';
import Navibar from './Navbar/Navibar'
import ProductPage from './ProductPage'
import Privacy from './Privacy'
import Archive from './Archive/Archive'
import Beta from './Beta/Beta'
import History from './History/History'
import { Helmet } from 'react-helmet'
// import {
//   BrowserRouter as Router,
//   Switch,
//   Route,
//   Link
// } from "react-router-dom"
import { withRouter } from 'react-router-dom';

class MainPage extends React.Component {

  constructor(props) {
    super(props);
    this.productID = props.pageID
    this.subID = props.subID
    //this.productID = props.location.pathname
    this.selectProductPage = this.selectProductPage.bind(this);
    var page_id = this.productID;
    var submenu_id = this.subID;
    this.state = { page_id: page_id, submenu_id: submenu_id };
    document.title = page_id;
  }

  componentDidMount() {
    document.body.scrollTop = 0;
  }

  selectProductPage(app_id, submenu_id) {
    this.productID = app_id;
    this.subID = submenu_id;
    this.setState({ page_id: app_id, submenu_id: submenu_id });
    var a_page_id = "/" + app_id ;
    //this.props.history.push(a_page_id)
    //document.title = a_page_id;
    //window.history.replaceState('page2', 'Title', a_page_id);
    //window.history.pushState('page2', 'Title', a_page_id);
  };

  handleScroll(event) {
    //this.setState({ page_id: "app_id", submenu_id: "submenu_id" });
  }

  render() {
    let selectProductPage = this.selectProductPage;
    let page_id = this.state.page_id;
    page_id = this.productID;
    console.log(this.productID)

    if (!page_id.match(/^(studiomux|touchable|beta|modstep|history|soda|touchable3|privacy|archive)$/)) {
      page_id = "zerodebug"
    }

    if (page_id !== "touchable" && page_id !== "studiomux" && page_id !== "history" && page_id !== "beta" && page_id !== "modstep" && page_id !== "soda" && page_id !== "touchable3" && page_id !== "privacy" && page_id !== "archive") {
      page_id = "zerodebug"
    } 
    let submenu_id = this.subID;
    
    if (submenu_id === "landing_page") {
    }
    else if (submenu_id === "manual") {
    }
    else if (submenu_id === "setup-mac" || submenu_id === "setup-win") {
    }
    else if (submenu_id === "faq") {
    }
    else {
      submenu_id = "landing_page";
    }



    let content;
    if (page_id !== "privacy" && page_id !== "archive" && page_id !== "beta" && page_id !== "history") {
      content = <ProductPage page_id={page_id} submenu_id={submenu_id} />
    }
    else if (page_id === "archive") {
      content = <Archive/>
    }
    else if (page_id === "beta") {
      content = <Beta/>
    }
    else if (page_id === "history") {
      content = <History/>
    }
    else {
      content = <Privacy/>
    }
    let margin_type;
    let opacity;
    if (page_id !== "szerodebug") {
      margin_type = "main_bar_margin"
    }
    else {
      let lastScrollY = window.scrollY;
      opacity = lastScrollY;
      if (lastScrollY > 56) {
        //margin_type = "sub_bar_margin"
      }
      else {
        //margin_type = "sub_bar_margin";
      }
    }


    return (
      <>
        <Helmet>
          <title>Zerodebug</title>
          <script> src="./javascripts.js"</script>
        </Helmet>

        <div class={margin_type}>
          <PageContent selectProductPage={selectProductPage} />
        </div>
      </>
    );

    function PageContent(props) {
      let selectProductPage = props.selectProductPage;
      return (
        <div class="mx-0">
        <div class="mx-0">
          <div>
              <Navibar selectProductPage={selectProductPage} page_id={page_id} submenu_id={submenu_id} opacity={opacity}/>
          </div>
          {content}
        </div>
        </div>
      );
    }
  }
}


export default withRouter(MainPage);