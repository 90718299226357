import React from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    NavLink
  } from "react-router-dom"

export default class PageFooter extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            width: 0, height: 0 
        };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }


    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
      }
      
      componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
      }
      
      updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
      }
    


    render() {


        let content = (
            <footer class="container" width="100%">
            <button class="navbar-toggler justify-content-start" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="row" id="navbarSupportedContent">
                    <div class="col-6 col-sm">
                        <h5 class="footer-heading footer_margin">Location</h5>
                        <ul class="list-unstyled text-small">
                            <li><a class="text-muted" href="#">Zerodebug</a></li>
                            <li><a class="text-muted" href="#">Niemetzstrasse 47</a></li>
                            <li><a class="text-muted" href="#">12055 Berlin</a></li>
                            <li><a class="text-muted" href="#">Germany</a></li>
                        </ul>
                    </div>
                    <div class="col-6  col-sm">
                        <h5 class="footer-heading footer_margin">Contact</h5>
                        <ul class="list-unstyled text-small">
                            <li><a class="text-muted" href="https://community.zerodebug.com/" target="blank">Forum</a></li>
                            <li><a class="text-muted" href="#"></a></li>
                        </ul>
                    </div>
                    <div class="col-6 col-sm">
                        <h5 class="footer-heading footer_margin">Products</h5>
                        <ul class="list-unstyled text-small">
                            <li><NavLink class="text-muted" to="touchable">touchAble Pro</NavLink></li>
                            <li><NavLink class="text-muted" to="studiomux">studiomux</NavLink></li>
                            <li><NavLink class="text-muted" to="soda">soda</NavLink></li>
                            <li><NavLink class="text-muted" to="modstep">modstep</NavLink></li>
                        </ul>
                    </div>
                    <div class="col-sm col-sm">
                        <h5 class="footer-heading footer_margin">Resources</h5>
                        <ul class="list-unstyled text-small">
                            <li><NavLink class="text-muted" to="/privacy">Privacy Policy</NavLink></li>
                            <li><NavLink class="text-muted" to="/archive">Downloads</NavLink></li>
                            <li><NavLink class="text-muted" to="/archive">Archive</NavLink></li>
                            <li><NavLink class="text-muted" to="/beta">Beta</NavLink></li>
                        </ul>
                    </div>
                </div>
                <div class="copyright-footer" style={{padding: '25px'}}>
                    <center>
                        <div class="">
                        &copy; 2020 <img src="./logo_zerodebug_white.png" width="30"></img> zerodebug. All Rights Reserved, zerodebug
                        </div>
                        <container height="25px">
                        </container>
                </center>
                </div>
            </footer>
        )

        if (this.state.width < 1000) {
            content = (<div class="my-2">
                        <ul class="list-unstyled text-small">
                            <li><a class="text-muted" href="https://community.zerodebug.com/" target="blank">Forum</a></li>
                            <li><NavLink class="text-muted" to="/privacy">Privacy Policy</NavLink></li>
                        </ul>
                    <div class="mb-2" style={{color: "white", borderWidth: "0px" }} >
                    <div class="copyright-footer" style={{padding: '25px'}}>
                                <center>
                        <div class="">
                        &copy; 2020 <img src="./logo_zerodebug_white.png" width="30"></img> zerodebug. All Rights Reserved, zerodebug
                        </div>
                        <container height="25px">
                        </container>
                </center></div></div></div>
            )
        }

        return (
                
                <div class="footer_styles">
                <div class="footer_margin">
                    {content}
                </div>
            </div>
        );
    }
}
