import React from 'react'
import server_data from '../General/server.json';

export default class ProductLandingContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            product: props.product,
            server_data: server_data,
            width: 0, height: 0 
        };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }


    componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  
  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }


    render() {
        var product = this.state.product;


        if (!product) {
            return (
                <ul class="navbar-nav w-100 justify-content-start">
                    <li class="nav-item active">
                        <a class="nav-link" href="https://www.zerodebug.com">no page found</a>
                    </li>
                </ul>
            );
        }

        let first_subcontainer = (
            <div class="text-block-main-right">
            </div>
        )

        let store_list = product.intro_field.app_store.map(store_item => (
            <li class="list-group-item flex-fill" style={{borderColor: "transparent", borderWidth: "0px" }}><a href={store_item.url} target="_blank"><h1 class="py-4 btn-outline-secondary" style={{borderColor: "transparent", borderWidth: "0px" }} href={store_item.url}><i class={store_item.class} aria-hidden="true"></i> {store_item.name}</h1></a></li>
        ))

        let store_part;

        if (product.intro_field.app_store.length > 0) {
            store_part = (
                <div class="my-1 mx-1 px-1 mx-md-5 px-md-5">
                            <ul class="list-group list-group-horizontal borderless  justify-content-center ">
                                {store_list}
                            </ul>
                </div>
            )
        }


        let page_id = this.state.product.app_id;
        const data = this.state.server_data;

        const products = data.products;
        var server_product;

        for (var i = 0; i < products.length; ++i) {
            var product_i = products[i];
            if (product_i.app_id === page_id) {
                server_product = product_i;
                break;
            }
        }

        let server_list = server_product.server_list.map(server_item => (
            <li class="list-group-item flex-fill" style={{borderColor: "transparent", borderWidth: "0px" }}><a href={server_item.url} target="_blank"><h1 class="p-4 btn-outline-secondary" style={{borderColor: "transparent", borderWidth: "0px" }} href={server_item.url}><i class={server_item.class} aria-hidden="true"></i> {server_item.name}</h1></a></li>
        ))

        let server_part;

        if (server_product.server_list.length > 0 && server_product.print_server === "yes") {
            server_part = (
                <div class="store-icon-list my-5 mx-1 px-1 mx-md-5 px-md-5">
                            <ul class="list-group list-group-horizontal borderless w-100 justify-content-center px-1">
                                {server_list}
                            </ul>
                </div>
            )
        }

        let content_heading = (<h1 class="" style={{ fontSize: "4vw" }}>{product.app_name_string}</h1>
        )
        let sub_heading = (<p class="" style={{ fontSize: "2vw", margin: "5%" }}>{product.intro_field.intro_text}</p>)
        let sub_sub_heading = (<p class="" style={{ fontSize: "2vw", margin: "5%" }}>{product.intro_field.sub_intro_text}</p>)

        let main_icon = (<img src={product.icon_source} width="6%"></img>)
        if (this.state.width < 1000) {
            content_heading = (<h1 class="" >{product.app_name_string}</h1>
            )
            sub_heading = (<h5 class="" style={{ }} >{product.intro_field.intro_text}</h5>)
            
            main_icon = (<img src={product.icon_source} width="10%"></img>)
            sub_sub_heading = (<h6 class="" style={{ fontSize: "2vw", margin: "5%" }}>{product.intro_field.sub_intro_text}</h6>)

        }

        let first_container = (<div class="position-relative overflow-hidden text-center container-main mw-100">
            <div class="glitch">
                <img class="img-fluid" src={product.intro_field.image_source} width="100%"></img>
                <div class="text-block-main ">
                    <div class="" style={{ marginTop: "10%" }}>
                        {main_icon}
                        {content_heading}
                        {sub_heading}
                        {sub_sub_heading}
                        {/* <h1 class="" style={{ }} >{product.app_name_string}</h1>
                        <h3 class="" style={{  margin: "5%"}} >{product.intro_field.intro_text}</h3> */}
                        {first_subcontainer}
                        {store_part}
                        {server_part}
                    </div>
                </div>
                 {first_subcontainer} 
            </div>
        </div>)


        return (
            <div class="">
                {first_container}
            </div>
        );
    }
}
