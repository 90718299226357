
import React from 'react'
import data from './ProductInstallPage.json';
import ProductInstallNavbar from './ProductInstallNavbar';
import server_data from '../General/server.json';

export default class StudiomuxMacInstallPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = { page_id: props.page_id, submenu_id: props.submenu_id, item_number: props.item_number, data: data, server_data: server_data};
        this.vector = {};
    }


    render() {

        let page_id = this.state.page_id;
        const data = this.state.server_data;

        const products = data.products;
        var product;

        var add_id = "";
        for (var i = 0; i < products.length; ++i) {
            var product_i = products[i];
            if (product_i.app_id == page_id) {
                product = product_i;
                break;
            }
        }

        if (!product) {
            return (
                <ul class="navbar-nav w-100 justify-content-start">
                    <li class="nav-item active">
                        <a class="nav-link" href="https://www.zerodebug.com">no page found</a>
                    </li>
                </ul>
            );
        }


        this.vector[0] = (
            <div>
            <div class="callout text-center">
                <h4>Download and install the app and server and make sure to deinstall previous versions of midimux and audiomux.</h4>
                <h3><a class="manual_link" href={product.server_list[0].url}>
                    <img src="images/pkg.png" alt="pkg-icon" class="app-icon" height="30"></img> {product.server_list[0].name}</a></h3>
                <div class="call">
                <h4>Once the server is installed and the mac has been restarted, a new icon will appear in the system tray.
The context menu displays the currently available and connected iOS devices and the status of the audio device.
On Mac OSX 10.8+ you can load or unload the audio device by clicking load/unload audio device.
Each status change requires an admin password entry.
You may quit the server by clicking quit and restart it – whenever you need it – from the Applications folder.</h4>
                </div>
            </div>
        </div>
        )

        this.vector[1] = (
            <div>
            <div class="callout text-left">
                <center>
                    <h3>Connect iOS device and mac via usb cable</h3>
                </center>
            </div>
        </div>
        )

        return (
            <div>
                {this.vector[this.state.item_number]}
            </div>
        );
    }
}






