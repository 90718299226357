
import React from 'react'
import data from './server_list.json'

export default class Archive extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            download_sources: data
        };
    }

    render() {

        const data = this.state.download_sources;

        const server_name_list = data.archive_list;

        let server_list = server_name_list.links.map(item => {
            var server_link = "https://zerodebug.com/server/"   + item.link_address
            return (
                <h4><li><a class="" href={server_link} target="blank">{item.link_address}</a></li></h4>
            )})

        return (
            <div class="">
            <h1 class="text-white">Full Download Archive</h1>
            <div class="">
            <ul class="list-group">
                {server_list}
                </ul>
            </div>
            </div>
        );
    }
}
