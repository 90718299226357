import React from 'react';
import MainPage from './components/MainPage'
import ScrollToTop from './ScrollToTop'
import {
  HashRouter as Router,
  Switch,
  Route,
  Link,
  withRouter,
} from "react-router-dom"

function App() {
  return (
    <div className="App">
        <ScrollToTop />
          <Route exact path="/" render={(props) => <MainPage {...props} pageID="" subID="" />} />
          <Route exact path="/zerodebug" render={(props) => <MainPage {...props} pageID="" subID="" />} />
          <Route exact path="/touchable" render={(props) => <MainPage {...props} pageID="touchable" subID=""/>} />
          <Route exact path="/touchable/setup-win" render={(props) => <MainPage {...props} pageID="touchable" subID="setup-win"/>} />
          <Route exact path="/touchable/setup-mac" render={(props) => <MainPage {...props} pageID="touchable" subID="setup-mac"/>} />
          <Route exact path="/touchable/manual" render={(props) => <MainPage {...props} pageID="touchable" subID="manual"/>} />
          <Route exact path="/touchable/faq" render={(props) => <MainPage {...props} pageID="touchable" subID="faq"/>} />
          <Route exact path="/studiomux" render={(props) => <MainPage {...props} pageID="studiomux" subID="" />} />
          <Route exact path="/studiomux/setup-win" render={(props) => <MainPage {...props} pageID="studiomux" subID="setup-win"/>} />
          <Route exact path="/studiomux/setup-mac" render={(props) => <MainPage {...props} pageID="studiomux" subID="setup-mac"/>} />
          <Route exact path="/studiomux/manual" render={(props) => <MainPage {...props} pageID="studiomux" subID="manual"/>} />
          <Route exact path="/soda" render={(props) => <MainPage {...props} pageID="soda" subID=""/>} />
          <Route exact path="/modstep" render={(props) => <MainPage {...props} pageID="modstep" subID=""/>} />
          <Route exact path="/touchable3" render={(props) => <MainPage {...props} pageID="touchable3" subID=""/>} />
          <Route exact path="/privacy" render={(props) => <MainPage {...props} pageID="privacy" subID=""/>} />
          <Route exact path="/archive" render={(props) => <MainPage {...props} pageID="archive" subID=""/>} />
          <Route exact path="/beta" render={(props) => <MainPage {...props} pageID="beta" subID=""/>} />
          <Route exact path="/history" render={(props) => <MainPage {...props} pageID="history" subID=""/>} />
    </div>
);
}



export default App;

